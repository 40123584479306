<template>
    <el-cascader :placeholder="placeholder" v-model="binds" :options="plus?regionDataPlus:option" size="large" filterable />
</template>

<script>
import { regionData,regionDataPlus } from 'element-china-area-data'
export default {
    name: "page-area",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        /* 初始值 */
        value: {type: String,default:''},
        plus: {type: Boolean,default: false},//loading
        split: {type: String,default: '-'},
        placeholder:{type: String,default:'试试搜索：北京'}
    },
    data() {
        return {
            binds:[],
            option:regionData,
            optionplus:regionDataPlus
        };
    },
    created() {
        if( this.value ){
			this.binds = this.value.split(this.split);
        }
    },
    methods: {
       
    },
    watch: {
        value(value) {
            this.binds = value?value.split(this.split):[];
        },
        binds(vals) {
            this.$emit('change',vals.join(this.split));
        }
    }
};
</script>